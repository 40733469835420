import React from 'react';

const IconWarning = ({ className }: { className: string }): React.ReactElement => (
    <svg
        className={className}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 90 79"
    >
        <path id="Fill-1" className="st0" d="M45,0L0,78.3h90L45,0L45,0z M45,6.7L84.2,75H5.8L45,6.7z" />
        <polygon id="Fill-2" className="st0" points="43.3,55 46.7,55 46.7,31.7 43.3,31.7 " />
        <path
            id="Fill-3"
            className="st0"
            d="M45,60c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C47.5,61.1,46.4,60,45,60"
        />
    </svg>
);
export default IconWarning;
