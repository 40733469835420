import { t as txt } from 'i18next';
import moment from 'moment/moment';
import { getValidLanguageTagForMoment, mapDeviceType } from 'commons/src/commonFunctions';
import { NetTypes } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { HealthStatusIssueTableRow, HealthStatusSummaryDevice } from '../../../models/buildingModels';

export const language = getValidLanguageTagForMoment();

export const formatLastSeen = (lastSeen?: string): string => {
    return lastSeen ? moment.utc(lastSeen).local().locale(language).fromNow() : '-';
};

export const formatDeviceConnection = (connection?: number): string => {
    return connection !== undefined && connection !== null ? `${connection} dbM` : '-';
};

export const formatHubConnection = (device: HealthStatusSummaryDevice): string => {
    if (!device.cell) return 'Ethernet';

    const strength = device.net === NetTypes['4G'] ? `(RSRQ: ${device.rsrq} dB)` : `(RSSI: ${device.rssi} dBm)`;
    const showStrength = device.net === NetTypes['4G'] ? Boolean(device.rsrq) : Boolean(device.rssi);

    return `SIM, ${device.net ?? ''} ${showStrength ? strength : ''}`;
};

export const formatBatteryPercentage = (batteryPercentage?: number): string => {
    return batteryPercentage !== undefined && batteryPercentage !== null ? `${batteryPercentage}%` : '-';
};

export const formatSegmentName = (segmentName?: string): string => {
    return segmentName || '-';
};

export enum IssueColumn {
    Buildings = 'buildings',
    Devices = 'devices',
    SerialNumber = 'serialNumber',
    Name = 'name',
    LastSeen = 'lastSeen',
    Connection = 'connection',
    Battery = 'battery',
}

export const issueCardHeaders: { key: IssueColumn; headerName: string }[] = [
    { key: IssueColumn.Buildings, headerName: 'Buildings' },
    { key: IssueColumn.Devices, headerName: 'Device' },
    { key: IssueColumn.SerialNumber, headerName: 'SerialNumber' },
    { key: IssueColumn.Name, headerName: 'Name' },
    { key: IssueColumn.LastSeen, headerName: 'BuildingStatus.LastSeen' },
    { key: IssueColumn.Connection, headerName: 'BuildingStatus.Connection' },
    { key: IssueColumn.Battery, headerName: 'HealthStatus.Battery' },
];

export const generateRows = (
    devices: HealthStatusSummaryDevice[],
    columns: IssueColumn[],
    csv: boolean
): HealthStatusIssueTableRow[] => {
    return devices.map((device, index) => {
        const row: Partial<HealthStatusIssueTableRow> = { isLast: index === devices.length - 1 };

        columns.forEach(column => {
            switch (column) {
                case IssueColumn.SerialNumber:
                    row.serialNumber = device.serialNumber;
                    break;
                case IssueColumn.Buildings:
                    row.buildings = devices.indexOf(device) === 0 ? device.locationName : '';
                    break;
                case IssueColumn.Devices:
                    row.devices = txt(`${mapDeviceType(device.deviceType as AnyDeviceType)}FullName`);
                    break;
                case IssueColumn.Name:
                    row.name = formatSegmentName(device.segmentName);
                    break;
                case IssueColumn.LastSeen:
                    row.lastSeen = formatLastSeen(device.lastSeen);
                    break;
                case IssueColumn.Connection:
                    if (device.deviceType === 'hub') {
                        row.connection = formatHubConnection(device);
                        break;
                    } else {
                        row.connection = formatDeviceConnection(device.rssi);
                        break;
                    }
                case IssueColumn.Battery:
                    row.battery = csv
                        ? formatBatteryPercentage(device.batteryPercentage)
                        : device.batteryPercentage?.toString();
                    break;
                default:
                    break;
            }
        });
        return row as HealthStatusIssueTableRow;
    });
};

export const generateCSVData = (
    headers: { key: keyof HealthStatusIssueTableRow; headerName: string }[],
    rows: HealthStatusIssueTableRow[]
): string[][] => {
    const csvHeaderNames = headers.map(header => txt(header.headerName));
    let lastBuildingName = '';
    const csvRowData = rows.map(row => {
        return headers.map(header => {
            let value = row[header.key];
            if (header.key === 'buildings') {
                if (value !== '') {
                    lastBuildingName = String(value);
                } else {
                    value = lastBuildingName;
                }
            }
            return value !== undefined ? String(value) : '';
        });
    });
    return [csvHeaderNames, ...csvRowData];
};
