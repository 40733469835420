import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mapDeviceType } from '../../commonFunctions';
import { dateFormats } from '../../constants';
import { AnyDeviceType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DeviceIcon from './DeviceIcon';
import styles from './DeviceInfo.module.scss';

export type Props = {
    serialNumber: string;
    segmentStart?: string;
    deviceType: AnyDeviceType;
    noMargin?: boolean;
};

export const DeviceInfoComponent = ({
    serialNumber,
    segmentStart,
    deviceType,
    noMargin,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { dateFormat } = useSelector((state: Store) => state.userSettings);
    const { calendarFormat } = dateFormats[dateFormat];

    return (
        <div className={noMargin ? styles.noMarginWrapper : styles.wrapper}>
            <div className={styles.icon}>
                <DeviceIcon deviceType={mapDeviceType(deviceType as AnyDeviceType)} />
            </div>
            <div className={styles.info}>
                <span>{[txt('DeviceType'), ': ', txt(`${mapDeviceType(deviceType as AnyDeviceType)}FullName`)]}</span>
                <span>{`${txt('SerialNumber')}: ${serialNumber}`}</span>
                {segmentStart && (
                    <span>{`${txt('MeasurementStarted')}: ${Moment(segmentStart).format(calendarFormat)}`}</span>
                )}
            </div>
        </div>
    );
};

export default DeviceInfoComponent;
