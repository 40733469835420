import React from 'react';

const checkmark = (
    <svg
        className="input-container__icon"
        width="16px"
        height="17px"
        viewBox="0 0 16 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="checkmark"
            d="M13.3133799,6.81974784 C13.3133799,6.64399575 13.2511679,6.46824367 13.1267438,6.34418337 L12.1831942,5.41373113 C12.0587701,5.28967083 11.8928713,5.21730232 11.7166038,5.21730232 C11.5403363,5.21730232 11.3744375,5.28967083 11.2500134,5.41373113 L7.01959362,9.62144291 L4.67627286,7.28497396 C4.55184874,7.16091366 4.38594993,7.08854516 4.20968244,7.08854516 C4.03341495,7.08854516 3.86751613,7.16091366 3.74309202,7.28497396 L2.79954251,8.2154262 C2.6751184,8.3394865 2.61290634,8.51523859 2.61290634,8.69099068 C2.61290634,8.86674276 2.6751184,9.0321565 2.79954251,9.15621679 L6.5530032,12.8987025 C6.67742731,13.0227628 6.85369481,13.0951313 7.01959362,13.0951313 C7.19586111,13.0951313 7.3721286,13.0227628 7.49655272,12.8987025 L13.1267438,7.28497396 C13.2511679,7.16091366 13.3133799,6.99549993 13.3133799,6.81974784 Z M15.9262863,8.49456187 C15.9262863,12.8780257 12.3594617,16.434421 7.96314313,16.434421 C3.56682453,16.434421 0,12.8780257 0,8.49456187 C0,4.111098 3.56682453,0.554702781 7.96314313,0.554702781 C12.3594617,0.554702781 15.9262863,4.111098 15.9262863,8.49456187 Z"
        />
    </svg>
);

export default checkmark;
