import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userRoleAboveRequiredLevel } from '../../features/authorization/userRoleAboveRequiredLevel';
import { LimitedSubscriptionReasons, RequiredRoleLevel } from '../../models/commonEnums';
import { Store } from '../../reducers';
import styles from './SubscriptionBanner.module.scss';

export const SubscriptionBanner = (): React.ReactElement | null => {
    const { subscriptionForGroup, selectedGroup } = useSelector((state: Store) => state.userSettings);
    const role = selectedGroup?.role;
    const userOnRequiredRoleLevel = role && userRoleAboveRequiredLevel(role, RequiredRoleLevel.ABOVE_ADMIN);

    if (!userOnRequiredRoleLevel || subscriptionForGroup?.reason !== LimitedSubscriptionReasons.missing_seats) {
        return null;
    }

    return (
        <div className={styles.banner}>
            <span className={styles.message}>
                <Trans
                    i18nKey="Subscription.MissingSeatsPleaseUpgrade"
                    values={{ devices: subscriptionForGroup?.missingSeatCount || '' }}
                >
                    <Link className={styles.link} to="/settings/subscription" />
                </Trans>
            </span>
        </div>
    );
};

export default SubscriptionBanner;
