import React, { useEffect } from 'react';
import { groupBy } from 'lodash';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import BatteryIcon from 'commons/src/components/device/BatteryIcon';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { batteryLevels } from 'commons/src/constants';
import deviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { fetchHealthStatusIssueData } from '../../../actions/healthStatusActions';
import { Store } from '../../../reducers';
import CardIssueTable from './CardIssueTable';
import styles from './StatusModal.module.scss';
import { generateCSVData, generateRows, issueCardHeaders, IssueColumn } from './statusModalCommonFunctions';

type ModalProps = {
    open: boolean;
    toggle: () => void;
    displayedNumber: number;
    header: string;
};

const LowBatteryStatusModal = ({ open, toggle, displayedNumber, header }: ModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            dispatch(fetchHealthStatusIssueData(deviceHealthStatus.lowBattery));
        }
    }, [open]);

    const lowBatteryDevices = useSelector((store: Store) => store.buildingsHealthStatus.lowBatteryDevices);

    const onClose = (): void => {
        toggle();
    };

    const groupedDevices = groupBy(lowBatteryDevices?.devices, 'locationId');

    const tableHeaders = issueCardHeaders.filter(h =>
        [
            IssueColumn.Buildings,
            IssueColumn.Devices,
            IssueColumn.Name,
            IssueColumn.LastSeen,
            IssueColumn.Battery,
        ].includes(h.key)
    );
    const selectedColumns = tableHeaders.map(h => h.key);

    const tableRows = Object.entries(groupedDevices).flatMap(([, devices]) =>
        generateRows(devices, selectedColumns, false)
    );

    const csvRows = Object.entries(groupedDevices).flatMap(([, devices]) =>
        generateRows(devices, selectedColumns, true)
    );

    const lowBatteryHeader = (
        <div className={styles.header}>
            <div className={styles.headerText}>
                <span className={styles.textBig}>{displayedNumber}</span>
                <span>{txt(header)}</span>
                <BatteryIcon batteryPercentage={batteryLevels.low} />
            </div>
            <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" />} testAttr="close" />
        </div>
    );

    return (
        <ModalWrapper isOpen={open} onClose={onClose} customHeader={lowBatteryHeader} size="large">
            <div className={styles.modalContent}>
                <div className={styles.tableWrapper}>
                    <CardIssueTable headers={tableHeaders} rows={tableRows} />
                </div>
                <CSVLink
                    data={generateCSVData(issueCardHeaders, csvRows)}
                    separator=";"
                    filename="Low_battery_hubs+devices.csv"
                    className={styles.downloadButton}
                >
                    {txt('BuildingStatus.DownloadList')}
                </CSVLink>
            </div>
        </ModalWrapper>
    );
};

export default LowBatteryStatusModal;
