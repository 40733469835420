import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCustomSegmentVirtualSensors } from 'commons/src/actions/DeviceActions';
import { businessPaths as paths, dateFormats, graphResolutions } from 'commons/src/constants';
import SensorGraph from 'commons/src/features/devicePage/SensorGraph';
import { SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { getPresenceSensorData } from '../../../actions/buildingOptimizationActions';
import { PresenceViewType } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import styles from './PresenceSelectedDevice.module.scss';

type ParentProps = {
    selectedDevice?: string;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    timeInUse?: string;
    dateFormat: keyof typeof dateFormats;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        name: TimePeriod;
        label?: string;
    };
    timeZone?: string;
    viewType: PresenceViewType;
};

type StateProps = {
    loadingSensorData: boolean;
    presenceDaySensorData: {
        [serialNumber: string]: {
            sensorData: number[][];
            averageValue: number | undefined;
            minValue: number | undefined;
        };
    };
    presenceDeviceSensorData: {
        [serialNumber: string]: {
            sensorData: number[][];
            averageValue: number | undefined;
            minValue: number | undefined;
        };
    };
};
type Props = ParentProps & StateProps;

const PresenceSelectedDevice = ({
    selectedDevice,
    devicesWithKeyInfo,
    timeInUse,
    selectedPeriod,
    dateFormat,
    loadingSensorData,
    viewType,
    timeZone,
    presenceDeviceSensorData,
    presenceDaySensorData,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const selectedDeviceInfo = selectedDevice ? devicesWithKeyInfo[selectedDevice] : undefined;
    const { fromDate, toDate, name, label } = selectedPeriod;

    const timeSpan = moment(toDate).diff(moment(fromDate), 'days');
    const resolution = timeSpan > 14 ? graphResolutions.fourHours : graphResolutions.full;
    const selectedInterval = {
        name,
        ...resolution,
        startDate: (timeZone ? moment.tz(fromDate, timeZone).startOf('day') : moment(fromDate)).startOf('day'),
        endDate: (timeZone ? moment.tz(toDate, timeZone).endOf('day') : moment(toDate)).endOf('day'),
        number: 1,
        label,
    };

    const fetchPresenceInsightData = (serialNumber: string): void => {
        dispatch(
            getPresenceSensorData({
                serialNumber,
                resolution: resolution.resolution,
                sensor: SensorTypes.presence,
                viewType,
                fromDate: selectedInterval.startDate.toISOString(),
                toDate: selectedInterval.endDate.toISOString(),
            })
        );
        dispatch(fetchCustomSegmentVirtualSensors(serialNumber, selectedInterval));
    };

    useEffect(() => {
        if (selectedDevice) {
            fetchPresenceInsightData(selectedDevice);
        }
    }, [selectedDevice]);

    const sensorType = SensorTypes.presence;
    const newSensorData = viewType === PresenceViewType.DayView ? presenceDaySensorData : presenceDeviceSensorData;
    const sensorDataForSerialNumber = (selectedDevice && newSensorData && newSensorData[selectedDevice]) || undefined;

    return (
        <div className={viewType === PresenceViewType.DayView ? styles.dayViewSelectedDevice : styles.selectedDevice}>
            {!selectedDevice ? (
                txt('BuildingInsight.SelectDevice')
            ) : (
                <>
                    {viewType === PresenceViewType.DeviceView && (
                        <>
                            <Link
                                className={styles.deviceName}
                                to={`/${paths.devices}/${selectedDevice}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {selectedDeviceInfo?.segmentName}
                            </Link>
                            <div className={styles.timeInUse}>
                                {txt('PresenceInsight.InUse')}:{timeInUse}
                            </div>
                        </>
                    )}
                    <div
                        className={
                            viewType === PresenceViewType.DayView ? styles.dayViewSensorGraph : styles.sensorGraph
                        }
                    >
                        <SensorGraph
                            chartData={
                                sensorDataForSerialNumber
                                    ? { [selectedInterval.name]: sensorDataForSerialNumber?.sensorData }
                                    : {}
                            }
                            unit="minutes"
                            thresholds={[]}
                            chartHeight={viewType === PresenceViewType.DayView ? 200 : 120}
                            selectedInterval={selectedInterval}
                            sensorType={sensorType}
                            minValues={{ [selectedInterval.name]: sensorDataForSerialNumber?.minValue || 0 }}
                            averageValues={{ [selectedInterval.name]: sensorDataForSerialNumber?.averageValue || 0 }}
                            dateFormat={dateFormat}
                            loadingVirtualSensor={loadingSensorData}
                            timeZone={timeZone}
                            showExtraSeriesName={false}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            [RequestType.GetPresenceSensorData]: { loading },
        },
        buildingOptimization: { presenceDaySensorData, presenceDeviceSensorData },
    } = store;
    return {
        loadingSensorData: loading,
        presenceDaySensorData,
        presenceDeviceSensorData,
    };
};

export default connect(mapStateToProps)(PresenceSelectedDevice);
