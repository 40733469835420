import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'business-dashboard/src/reducers';
import { FeatureToggleEnum, GroupType, Role } from '../../models/commonEnums';
import { ActionButton, OptionButtonType } from '../../models/menuModels';
import OptionButton from '../buttons/OptionButton';
import PrimaryButton from '../buttons/PrimaryButton';
import TertiaryButton from '../buttons/TertiaryButton';
import SearchComponent from '../input/SearchComponent';
import MaterialIcon from '../MaterialIcon';
import filterMenuElements from '../menu/FilterMenuElements';
import styles from './SubHeader.module.scss';

type SearchElementParams = {
    onSearchUpdate?: (text: string) => void;
    disabled?: boolean;
};

export type Props = {
    actionButtons: ActionButton[];
    optionButton?: OptionButtonType;
    onGoBack?: () => void;
    backTitle?: string;
    small?: boolean;
} & SearchElementParams;

export const renderActionButtons = ({
    actionButtons,
    userRole,
    featureToggles,
    groupType,
}: {
    actionButtons: ActionButton[];
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
    small?: boolean;
}): React.ReactElement[] =>
    filterMenuElements(actionButtons, groupType, userRole, undefined, featureToggles).map(button => (
        <PrimaryButton
            key={button.id}
            onClick={button.onClick}
            title={button.title}
            icon={button.icon}
            color={button.color}
            testAttr={button.testAttr}
            testId={button.testAttr}
            disabled={button.disabled}
        />
    ));

const SubHeader = ({
    onSearchUpdate,
    actionButtons,
    optionButton,
    onGoBack,
    backTitle,
    disabled,
    small,
}: Props): React.ReactElement => {
    const { selectedGroup, featureToggles } = useSelector((store: Store) => store.userSettings);
    const userRole = selectedGroup && selectedGroup.role;
    const groupType = selectedGroup?.groupType;

    return (
        <div className={!onSearchUpdate && !onGoBack ? styles.onlyButtons : styles.subHeader}>
            {onGoBack && (
                <TertiaryButton
                    onClick={onGoBack}
                    title={backTitle || 'back'}
                    icon={<MaterialIcon name="chevron_left" />}
                    testId="sub-header-back-button"
                />
            )}
            {onSearchUpdate && (
                <SearchComponent onChange={onSearchUpdate} disabled={disabled} size={small ? 'sm' : 'lg'} expandable />
            )}
            <div className={styles.buttonContainer}>
                {renderActionButtons({ actionButtons, userRole, featureToggles, groupType, small })}
                {optionButton && (
                    <OptionButton
                        id={optionButton.id}
                        title={optionButton.title}
                        testAttr={optionButton.testAttr}
                        options={optionButton.options}
                        onClick={optionButton.onClick}
                        requiredRoleLevel={optionButton.requiredRoleLevel}
                        accessWithResourceFilter={optionButton.accessWithResourceFilter}
                    />
                )}
            </div>
        </div>
    );
};

export default SubHeader;
