import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import config from '../../config';
import { Role } from '../../models/commonEnums';
import { Store } from '../../reducers';
import styles from './SupportBanner.module.scss';

export const SupportBanner = (): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const { groups } = useSelector((state: Store) => state.userSettings);
    const supportUser = groups.length === 1 && groups[0].role === Role.SUPPORT ? groups[0] : undefined;
    if (!supportUser) {
        return null;
    }

    const userGroupId = (supportUser && supportUser.id) || '';
    const url = `${config.backofficeUrl}/usergroup/${userGroupId}`;

    return (
        <div className={styles.banner}>
            <span className={styles.message}>
                {txt('SupportBanner.Title')}
                <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
                    {txt('SupportBanner.Here')}.
                </a>
            </span>
        </div>
    );
};

export default SupportBanner;
