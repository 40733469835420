import React, { FocusEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { mobileMax } from '../../constants';
import { TabOption } from '../../models/menuModels';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import SubMenuMobile from '../submenu/TabMenuMobile';
import styles from './HeaderTabNavigation.module.scss';
import TabSubNavigation from './TabSubNavigation';

export type Props = {
    tabs: TabOption[];
};

const isMatchingTab = (pathname: string, tab: TabOption): boolean => tab && !!matchPath(tab.route, pathname);

export const HeaderTabNavigationComponent = ({ tabs }: Props): React.ReactElement => {
    const location = useLocation();
    const { mobileMenuIsOpen } = useSelector((state: Store) => state.userSettings);
    const getInitialPath = (): number => {
        const initialPath = location.pathname;
        const indexOfInitialPath = tabs.findIndex(tab => isMatchingTab(initialPath, tab));
        return indexOfInitialPath >= 0 ? indexOfInitialPath : 0;
    };

    const [activeTab, setActiveTab] = useState(getInitialPath());
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [subMenuOpen, setSubMenuOpen] = useState(-1);

    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const onWindowSizeChange = (): void => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        const newActiveTab = getInitialPath();
        const newActiveTabProps = tabs[newActiveTab];
        if (newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
            if (newActiveTabProps?.subNavigation) {
                setActiveTab(newActiveTab);
                setSubMenuOpen(-1);
            }
        }
    }, [location.pathname]);

    useEffect((): (() => void) => {
        window.addEventListener('resize', onWindowSizeChange);
        return (): void => window.removeEventListener('resize', onWindowSizeChange);
    }, []);
    const isMobile = width <= mobileMax;

    const getSelectedMenuByPath = (path: string): TabOption => tabs.find(tab => isMatchingTab(path, tab)) || tabs[0];

    const navigateToTabPageContent = (newTabIndex: number): void => {
        const tab = tabs[newTabIndex];
        if (!isMatchingTab(window.location.pathname, tab)) {
            if (tab?.data != null) {
                navigate(tab.path, {
                    state: tab.data,
                });
            } else {
                navigate(tab.path);
            }
        }
    };

    const selectTab = (index: number): void => {
        const elementWithSubMenu = !!tabs[index]?.subNavigation;
        if (elementWithSubMenu) {
            setSubMenuOpen(index === subMenuOpen ? -1 : index);
        } else if (index !== activeTab) {
            setSubMenuOpen(-1);
            setActiveTab(index);
            navigateToTabPageContent(index);
        }
    };

    const onBlur = (e: FocusEvent<HTMLElement>, index: number): void => {
        if (index === subMenuOpen) {
            const enterTarget = e.relatedTarget;
            const settingsMenu: Node | null = document.querySelector('.tab-menu-mobile__dropdown');
            if (!enterTarget || !settingsMenu || !settingsMenu.contains(enterTarget)) {
                // Need the timer to allow mobile to redirect before closing the menu
                setTimeout(() => {
                    setSubMenuOpen(-1);
                }, 100);
            }
        }
    };

    const onChangeTab = (event: React.SyntheticEvent, newTab: number): void => {
        selectTab(newTab);
    };

    const openSubMenuProps = tabs[subMenuOpen];

    return (
        <>
            {mobileMenuIsOpen && <div className={styles.menuModal} />}
            {isMobile ? (
                <SubMenuMobile menus={tabs} getSelectedMenuByPath={getSelectedMenuByPath} />
            ) : (
                <div className={styles.pageTabs}>
                    {openSubMenuProps && openSubMenuProps.subNavigation && (
                        <TabSubNavigation
                            index={subMenuOpen}
                            closeSubMenu={(): void => setSubMenuOpen(-1)}
                            subNavigation={openSubMenuProps.subNavigation}
                            tab={openSubMenuProps}
                            onBlur={onBlur}
                        />
                    )}
                    <Tabs
                        textColor="inherit"
                        indicatorColor="secondary"
                        value={activeTab}
                        onChange={onChangeTab}
                        aria-label="menu tabs"
                        TabIndicatorProps={{ sx: { height: '3px' } }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab.id}
                                aria-controls={`simple-tabpanel-${index}`}
                                value={index}
                                iconPosition="end"
                                onClick={(): void => selectTab(index)}
                                {...{ [`data-tab-${tab.testAttr}`]: true }}
                                label={
                                    tab.subNavigation ? (
                                        <div className={styles.dropdownHeader}>
                                            {txt(tab.text)}
                                            <MaterialIcon name="keyboard_arrow_down" />
                                        </div>
                                    ) : (
                                        <>
                                            <span>{txt(tab.text)}</span>
                                            {tab.badge ? (
                                                <span className={styles.endIcon}>{tab.badge}</span>
                                            ) : undefined}
                                        </>
                                    )
                                }
                            />
                        ))}
                    </Tabs>
                </div>
            )}
        </>
    );
};
export default HeaderTabNavigationComponent;
