import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import styles from './WizardSectionHeader.module.scss';

type Props = {
    sectionNumber: string;
    name: string;
    description: string;
    openClose?: (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLDivElement>) => void;
    headerAsButton: boolean;
    headerClosed: boolean;
    headerInactive: boolean;
    sectionId: string;
};

const WizardSectionHeader = ({
    sectionNumber,
    name,
    description,
    openClose,
    headerAsButton,
    headerClosed,
    headerInactive,
    sectionId,
}: Props): React.ReactElement => {
    return (
        <div
            tabIndex={0}
            role="button"
            id={sectionId}
            onKeyUp={openClose}
            onClick={openClose}
            className={classNames(styles.header, {
                [styles.button]: headerAsButton,
                [styles.sectionClosed]: headerClosed,
            })}
        >
            <h2 className={headerInactive ? styles.inactiveText : styles.text}>
                {sectionNumber}
                {name}
            </h2>
            <span className={styles.summary}>{description}</span>
        </div>
    );
};

export default WizardSectionHeader;
