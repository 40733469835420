import React from 'react';
import * as Sentry from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getDashboardPath } from '../../commonFunctions';
import { groupRequiredRole } from '../../features/authorization/userRoleAboveRequiredLevel';
import { Store } from '../../reducers';
import PageWrapper from '../containers/PageWrapper';
import MaterialIcon from '../MaterialIcon';
import styles from './ForbiddenAccess.module.scss';

const ForbiddenAccessComponent = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [searchParams] = useSearchParams();
    const { selectedGroup } = useSelector((store: Store) => store.userSettings);
    const userRole = selectedGroup && selectedGroup.role;
    const userGroupId = selectedGroup && selectedGroup.id;

    const requiredRoleLevel = searchParams.get('requiredRoleLevel') || 'unknown';
    const allowedRoles = ((requiredRoleLevel && groupRequiredRole[requiredRoleLevel]) || []).join(',');
    if (Sentry) {
        Sentry.captureMessage(
            `Redirected to 403 due to user being: ${userRole}, role not in ${allowedRoles} for ${userGroupId}`,
            'warning'
        );
    }
    return (
        <PageWrapper pageType="medium">
            <div className={styles.section}>
                <MaterialIcon extraClass={styles.icon} name="lock" />
                <p className={styles.header}>{txt('ForbiddenPage.Title')}</p>
                <p className={styles.text}>
                    {txt('ForbiddenPage.AccessRole', { role: txt(`AuthorizationRole.${userRole}`) })}
                </p>
                <p className={styles.text}>{txt('ForbiddenPage.ContactAdmin')}</p>
                <p className={styles.thinText}>
                    <Trans i18nKey="ForbiddenPage.GoToDashboard">
                        <Link className={styles.linkText} to={getDashboardPath()} />
                    </Trans>
                </p>
            </div>
        </PageWrapper>
    );
};
export default ForbiddenAccessComponent;
