import React, { useEffect, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { logOut } from '../../actions/LoginAndRegisterActions';
import { Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import ChangeOrganizationModal from './ChangeOrganizationModal';
import HamburgerIcon, { hamburgerTargetIds } from './HamburgerIcon';
import NavigationItem from './NavigationItem';
import NoSubscription from './NoSubscription';

type StateProps = {
    selectedGroup?: Group;
    groups: Group[];
    userName: string;
};

type PassedProps = {
    navigationItems: { id: string; key: string; path: string; title: string; iconName: string }[];
};

type Props = StateProps & PassedProps;

export const HamburgerMenuComponent = ({
    navigationItems,
    selectedGroup,
    groups,
    userName,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [burgerOpen, setBurgerState] = useState(false);
    const [changeOrganizationModalOpen, setChangeOrganizationModalOpen] = useState(false);

    const burgerToggle = (): void => {
        setBurgerState(!burgerOpen);
    };

    const outsideClickListener = (e: MouseEvent<HTMLElement>): void => {
        // this needs to be target - not currentTarget as this will not include the classNames
        if (e.target && !hamburgerTargetIds.includes(e.target.id)) {
            setBurgerState(false);
        }
    };
    const onLogOut = (): void => {
        dispatch(logOut());
    };

    const flipChangeOrgState = (): void => {
        setChangeOrganizationModalOpen(!changeOrganizationModalOpen);
    };

    const navItems = (): React.ReactElement[] =>
        navigationItems.map(item => (
            <NavigationItem
                id={item.id}
                key={item.key}
                path={item.path}
                title={item.title}
                iconName={item.iconName}
                navigationType="hamburger"
            />
        ));

    useEffect((): (() => void) => {
        window.addEventListener('click', outsideClickListener);
        return (): void => window.removeEventListener('click', outsideClickListener);
    }, [outsideClickListener]);

    const displayChangeAccount = groups.length > 1;
    const currentAccountName = (selectedGroup && selectedGroup.organizationName) || userName;
    return (
        <div className="hamburger-navigation-container">
            {changeOrganizationModalOpen && <ChangeOrganizationModal onClose={flipChangeOrgState} />}
            <div id="hamburgerHeader" className="hamburger-navigation">
                <div className="hamburger-navigation__items-container">
                    <HamburgerIcon open={burgerOpen} onClick={burgerToggle} />
                </div>
            </div>
            {burgerOpen && (
                <div
                    className={classNames('hamburger-navigation__items-container--hamburger__links', {
                        open: burgerOpen,
                    })}
                >
                    {displayChangeAccount && (
                        <li
                            className={classNames(
                                'sidebar-navigation__items-container__item',
                                'sidebar-navigation__items-container__item--function-item',
                                'sidebar-navigation__items-container__item--function-item--border-bottom'
                            )}
                        >
                            <div
                                tabIndex={0}
                                className="sidebar-navigation__items-container__element"
                                role="button"
                                onKeyUp={(e): void => {
                                    if (e.key === 'enter') flipChangeOrgState();
                                }}
                                onClick={flipChangeOrgState}
                            >
                                <MaterialIcon
                                    extraClass="sidebar-navigation__items-container__item__icon"
                                    name="swap_horizontal_circle"
                                />
                                <div className="sidebar-navigation__items-container__item--ellipsis">
                                    {currentAccountName}
                                </div>
                            </div>
                        </li>
                    )}
                    <NoSubscription />
                    {navItems()}
                    <li className="sidebar-navigation__items-container__item sidebar-navigation__items-container__item--function-item">
                        <div
                            tabIndex={0}
                            className="sidebar-navigation__items-container__element sidebar-navigation__items-container__item--center"
                            role="button"
                            onKeyUp={(e): void => {
                                if (e.key === 'enter') onLogOut();
                            }}
                            onClick={onLogOut}
                        >
                            <MaterialIcon
                                extraClass="sidebar-navigation__items-container__item__icon"
                                name="exit_to_app"
                            />
                            {txt('Logout')}
                        </div>
                    </li>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { groups, userName, selectedGroup },
    } = state;

    return {
        selectedGroup,
        groups,
        userName,
    };
};

export default connect(mapStateToProps)(HamburgerMenuComponent);
