import React from 'react';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from 'commons/src/constants';
import { EnumStatusColors } from '../../models/commonEnums';
import SensorStatusDot from '../sensors/SensorStatusDot';
import CustomSlider from '../slider/CustomSlider';
import styles from './SensorSlider.module.scss';

export type Props = {
    sensor: string;
    unit: keyof typeof sensorUnits;
    value: number;
    min: number;
    max: number;
    updateSlider: (thresholdValue: number, rangeColor: string) => void;
    color: EnumStatusColors;
};

const SensorSliderComponent = ({ color, sensor, unit, value, max, min, updateSlider }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const onChange = (e: Event, sliderValues: number[]): void => {
        const thresholdValue = sliderValues[0];
        updateSlider(thresholdValue, color);
    };
    return (
        <div>
            <div className="input__slider">
                <SensorStatusDot color={color} />
                <span>
                    {[
                        txt(sensor),
                        ` (${sensorUnits[unit]}) - `,
                        txt(color === 'red' ? 'LedBehaviour.RedLimit' : 'LedBehaviour.YellowLimit'),
                    ]}
                </span>
            </div>
            <div className={styles.wrapper}>
                <CustomSlider
                    value={[value]}
                    onChange={onChange}
                    size="small"
                    min={min}
                    max={max}
                    step={50}
                    valueLabelDisplay="on"
                    aria-label={txt('CustomThresholds.Slider')}
                />
            </div>
        </div>
    );
};

export default SensorSliderComponent;
