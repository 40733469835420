import React from 'react';
import { useTranslation } from 'react-i18next';
import IconWarning from '../../img/icon-warning';
import PageWrapper from '../containers/PageWrapper';
import styles from './Error.module.scss';

const NotSupported = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <PageWrapper pageType="medium">
            <div className={styles.section}>
                <IconWarning className={styles.icon} />
                <p className={styles.header}>{txt('NotSupported')}</p>
            </div>
        </PageWrapper>
    );
};

export default NotSupported;
