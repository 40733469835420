import React, { SyntheticEvent } from 'react';
import RadioButtons from './Radio';
import styles from './RadioSelector.module.scss';

export type Props = {
    headerKey: string;
    options: { displayedValue: string; id: string }[];
    onSelect: (selectionName: string, selectedOptionId: string) => void;
    selectionName: string;
    selectedId: string;
};

const RadioSelectorComponent = ({
    headerKey,
    onSelect,
    options,
    selectionName,
    selectedId,
}: Props): React.ReactElement => {
    const changeSelection = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const selectorName = e.currentTarget.name;
        onSelect(selectorName, value);
    };
    const radioOptions = options.map(opt => ({
        value: opt.id,
        label: opt.displayedValue,
    }));

    return (
        <div className={styles.wrapper}>
            <RadioButtons
                header={headerKey}
                buttons={radioOptions}
                selectorName={selectionName}
                row
                onChange={changeSelection}
                value={selectedId}
                labelId={`user-settings-${selectionName}`}
            />
        </div>
    );
};

export default RadioSelectorComponent;
