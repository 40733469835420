/* eslint max-len: ["error", 1000] */
import React from 'react';

const Empty = ({ className }: { className: string }): React.ReactElement => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 52 46.9" className={className}>
        <path d="M52,36V0H0v36h25v3.9l-9.5,5.2l1,1.8l9.5-5.3l9.5,5.3l1-1.8L27,39.9V36H52z M2,2h48v32H2V2z" />
        <polygon points="24.7,22.4 18.8,14.1 14.8,21.8 8.5,9.2 4,26 5.9,26.5 9.1,14.8 14.7,26.2 19,17.9 25.4,26.8 31,8.3 29,7.7 " />
        <path d="M40,12c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S43.3,12,40,12z M40,22c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S42.2,22,40,22z" />
    </svg>
);

export default Empty;
