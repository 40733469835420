import React from 'react';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, NavLink } from 'react-router-dom';
import NotFoundIcon from '../../img/icon-magic-wand';
import PageWrapper from '../containers/PageWrapper';
import styles from './PageNotFound.module.scss';

type PageNotFoundCode =
    | 'buildingNotFound'
    | 'serialNumberNotDefined'
    | 'serialNumberNotDefinedOrNotInDevices'
    | 'segmentIdOrSerialNumberNotDefined'
    | 'notAPartnerOrg'
    | 'invalidHub'
    | 'tileNotFoundForLocationId';

export const pageNotFoundUrl = (warningcode: PageNotFoundCode, params: { [key: string]: unknown } = {}): string =>
    `/404?${createSearchParams({ warningcode, ...params }).toString()}`;

const getSeverity = (warningCode?: PageNotFoundCode): SeverityLevel => {
    switch (warningCode) {
        default:
            return 'error';
    }
};

const PageNotFound = (): React.ReactElement => {
    if (Sentry) {
        const warningCode = new URLSearchParams(window.location.search).get('warningcode') as PageNotFoundCode;
        Sentry.captureEvent({
            message: `redirected to 404${warningCode ? ` (${warningCode})` : ''}`,
            level: getSeverity(warningCode),
            tags: { code: warningCode },
        });
    }
    const { t: txt } = useTranslation();
    return (
        <PageWrapper pageType="medium">
            <div className={styles.section}>
                <NotFoundIcon className={styles.icon} />
                <p className={styles.header}>{txt('PageNotFound')}</p>
                <p className={styles.subHeader}>
                    {txt('PageNotFoundSubtitle')}
                    <NavLink className={styles.link} to="/">
                        {txt('Dashboard').toLowerCase()}
                    </NavLink>
                </p>
            </div>
        </PageWrapper>
    );
};

export default PageNotFound;
