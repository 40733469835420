import React from 'react';
import QRCodeSVG from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HorizontalBusinessLogo from '../../img/logos/airthings_business_logo_horizontal';
import { Store } from '../../reducers';
import styles from './QRLabel.module.scss';

interface Props {
    shortUrl: string;
    url: string;
}

const QRLabel = ({ shortUrl, url }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { selectedGroup } = useSelector((state: Store) => state.userSettings);
    const { logoImage } = useSelector((state: Store) => state.organizationProperties);

    return (
        <div className={styles.wrapper}>
            <div className={styles.blueSection}>
                <div className={styles.headerSection}>
                    <div className={styles.header}>{txt('PublicIAQDashboard.StickerHeader')}</div>
                    <div className={styles.subHeader}>{txt('PublicIAQDashboard.StickerDescription')}</div>
                </div>
                {HorizontalBusinessLogo(styles.airthingsLogo)}
            </div>
            <div className={styles.whiteSection}>
                {logoImage ? (
                    <img
                        src={logoImage}
                        className={styles.organizationLogo}
                        alt={txt('OrganizationProfile.LogoImage')}
                    />
                ) : (
                    <div className={styles.organizationName}>{selectedGroup && selectedGroup.organizationName}</div>
                )}
                <QRCodeSVG value={url} size={102} id="qrCode" />
                <div className={styles.qrLabel}>{shortUrl}</div>
            </div>
        </div>
    );
};

export default QRLabel;
