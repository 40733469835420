import React from 'react';
import classNames from 'classnames';
import styles from './HamburgerIcon.module.scss';

export const hamburgerTargetIds = [
    'hamburgerIcon',
    'hamburgerLine1',
    'hamburgerLine2',
    'hamburgerLine3',
    'hamburgerButton',
];

type Props = {
    open: boolean;
    onClick: () => void;
};

const HamburgerIcon = ({ open, onClick }: Props): React.ReactElement => (
    <button
        id="hamburgerButton"
        type="button"
        data-testid="data-hamburger-button"
        className={styles.wrapper}
        onClick={onClick}
    >
        <div id="hamburgerIcon" className={styles.menuIcon}>
            <span id="hamburgerLine1" className={classNames(styles.firstLine, { [styles.firstLineOpen]: open })} />
            <span id="hamburgerLine2" className={classNames(styles.secondLine, { [styles.secondLineOpen]: open })} />
            <span id="hamburgerLine3" className={classNames(styles.thirdLine, { [styles.thirdLineOpen]: open })} />
        </div>
    </button>
);

export default HamburgerIcon;
