import React from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sensorFullNameKey } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { DayUsageHours } from 'commons/src/models/commonTypeScript';
import { IaqInsightData, ReportSensorThresholds } from '../../../models/common';
import { Store } from '../../../reducers';
import CircledAverage from './CircledAverage';
import IaqInsightGraph from './InsightGraph';
import ResultTable from './ResultTable';
import ThresholdRanges from './ThresholdRanges';

export type ParentProps = {
    sensorReport: IaqInsightData;
    sensor: SensorTypes;
    usageHours: { [day: string]: DayUsageHours };
    from: Moment | null;
    to: Moment | null;
    displayGraph: boolean;
    timeZone: string;
    thresholds: { [sensor: string]: ReportSensorThresholds };
};

type StateProps = {
    thresholds: { [sensor: string]: ReportSensorThresholds };
};

type Props = StateProps & ParentProps;

export const SensorResultComponent = (props: Props): React.ReactElement => {
    const { displayGraph, from, sensor, sensorReport, thresholds, to, usageHours, timeZone } = props;
    const { t: txt } = useTranslation();
    const sensorRanges = thresholds[sensor];
    const sensorText = txt(sensorFullNameKey(sensor));

    return (
        <div className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header insight-tile__header--no-border insight-tile__header--padded">
                <div>
                    <h2 className="insight-tile__header__sensor">{sensorText}</h2>
                    <ThresholdRanges
                        ranges={sensorRanges.ranges}
                        unit={sensorRanges.unit}
                        sensorType={sensorRanges.type}
                    />
                </div>
                <div>
                    <CircledAverage
                        ranges={sensorRanges.ranges}
                        value={sensorReport.averageValueWithinOpeningHours}
                        unit={sensorRanges.unit}
                        sensorType={sensor}
                        headerText="InsightIaq.AverageWithinOpeningHours"
                    />
                    <CircledAverage
                        ranges={sensorRanges.ranges}
                        value={sensorReport.averageValue}
                        unit={sensorRanges.unit}
                        sensorType={sensor}
                        headerText="InsightIaq.AverageValueNoException"
                        hideCircle
                    />
                </div>
            </div>
            <p>{txt(`InsightIaq.${sensor}Description`)}</p>
            {displayGraph && (
                <div id="insightGraph" className="insight-tile__content insight-tile__content--graph">
                    <IaqInsightGraph
                        usageHours={usageHours}
                        chartData={sensorReport.averageGraph || []}
                        ranges={sensorRanges.ranges}
                        unit={sensorRanges.unit}
                        sensor={sensor}
                        from={from}
                        to={to}
                        graphId={sensor}
                        timeZone={timeZone}
                    />
                </div>
            )}
            <ResultTable
                sensorReport={sensorReport}
                sensor={sensor}
                usageHours={usageHours}
                from={from}
                to={to}
                timeZone={timeZone}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        iaqInsight: { thresholds },
    } = state;
    return {
        thresholds,
    };
};

export default connect(mapStateToProps)(SensorResultComponent);
