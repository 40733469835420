import React, { SyntheticEvent, KeyboardEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import styles from './Checkbox.module.scss';

type CheckBoxColor = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default' | undefined;
type Props = {
    label: string | React.ReactElement;
    labelIsElement?: boolean;
    id: string;
    name?: string;
    required?: boolean;
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
    checked: boolean;
    testAttr?: string;
    skipTranslate?: boolean;
    testId?: string;
    indeterminate?: boolean;
    disabled?: boolean;
    color?: CheckBoxColor;
};

const CheckBox = ({
    label,
    id,
    name,
    onChange,
    checked,
    required,
    testAttr,
    testId,
    skipTranslate = false,
    labelIsElement = false,
    indeterminate,
    disabled,
    color,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const check = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const innerElement = e.currentTarget.querySelector('input');
            onChange({ ...e, currentTarget: (innerElement as HTMLInputElement) || e.currentTarget });
        }
    };

    const showLabel = (): React.ReactElement => {
        if (labelIsElement) {
            return <div className={styles.checkboxLabel}>{label}</div>;
        }
        return <div className={styles.checkboxLabel}>{skipTranslate ? label : txt(label as string)} </div>;
    };

    return (
        <div className={styles.container}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        value={id}
                        name={name || id}
                        required={required || false}
                        indeterminate={indeterminate || false}
                        disabled={disabled || false}
                        checked={checked}
                        onKeyDown={check}
                        onChange={onChange}
                        color={color || 'primary'}
                        inputProps={{
                            'data-testid': testId || '',
                            ...{ [`data-${testAttr || 'checkbox'}`]: true },
                        }}
                    />
                }
                label={showLabel()}
            />
        </div>
    );
};

export default CheckBox;
