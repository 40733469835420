import React, { KeyboardEvent } from 'react';
import * as Sentry from '@sentry/react';
import { Moment } from 'moment';
import { connect } from 'react-redux';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import { SensorData, DayUsageHours } from 'commons/src/models/commonTypeScript';
import { IaqInsightData, ReportSensorThresholds } from '../../../../models/common';
import { Store } from '../../../../reducers';
import IaqInsightGraph from '../InsightGraph';
import TableRowDeviceNameAndStatus from './TableRowDeviceNameAndStatus';
import TableRowSensorStatus from './TableRowSensorStatus';

export type DeviceReport = {
    name: string;
    serialNumber: string;
    average?: number;
    highestMeasurement?: number;
    lowestMeasurement?: number;
    roomType: string | undefined;
};
export type ParentProps = {
    deviceReport: DeviceReport;
    sensor: string;
    usageHours: { [day: string]: DayUsageHours };
    from: Moment | null;
    to: Moment | null;
    sensorRanges: ReportSensorThresholds;
    clickSneakPeak: (serialNumber: string) => void;
    showSneakPeak: boolean;
    sensorReports: IaqInsightData;
    timeZone: string;
};
type StateProps = {
    sensorData: { [serialNumber: string]: SensorData };
};

type Props = StateProps & ParentProps;

export const InsightTableRowComponent = (props: Props): React.ReactElement => {
    const {
        usageHours,
        from,
        to,
        deviceReport,
        showSneakPeak,
        sensor,
        sensorRanges,
        sensorData,
        clickSneakPeak,
        sensorReports,
        timeZone,
    } = props;
    const { serialNumber, highestMeasurement, lowestMeasurement, average, roomType, name } = deviceReport;

    const rowContent = (
        <>
            <div className="insight-table__cell insight-table__cell--first-in-row">
                <TableRowDeviceNameAndStatus
                    ranges={sensorRanges.ranges}
                    average={average}
                    roomType={roomType}
                    name={name}
                />
            </div>
            <div className="insight-table__cell">
                <TableRowSensorStatus sensorRanges={sensorRanges} sensor={sensor} isAverage value={average} />
            </div>
            <div className="insight-table__cell">
                <TableRowSensorStatus
                    sensorRanges={sensorRanges}
                    sensor={sensor}
                    isAverage={false}
                    value={lowestMeasurement}
                />
            </div>
            <div className="insight-table__cell">
                <TableRowSensorStatus
                    sensorRanges={sensorRanges}
                    sensor={sensor}
                    isAverage={false}
                    value={highestMeasurement}
                />
            </div>
        </>
    );

    let sensorGraphData: number[][] | undefined;
    if (sensorData[serialNumber] && sensorData[serialNumber][sensor] && sensorData[serialNumber][sensor].custom) {
        if (sensorReports.averageGraph && sensorReports.averageGraph.length > 0) {
            const sensorReportStart = sensorReports.averageGraph[0][0];
            const sensorReportEnd = sensorReports.averageGraph[sensorReports.averageGraph.length - 1][0];

            sensorGraphData = sensorData[serialNumber][sensor].custom.filter(
                data => data[0] >= sensorReportStart && data[0] <= sensorReportEnd
            );
        } else {
            Sentry.captureEvent({
                message: 'Missing averageGraph on sensorReports',
                extra: { sensorReports, serialNumber, sensor, sensorData },
                level: 'debug',
            });
        }
    }

    const sneakPeakMode = showSneakPeak && (
        <div
            key={`sneak-peak-${serialNumber}`}
            id="insightGraph"
            className="insight-tile__content insight-tile__content--graph"
        >
            {sensorGraphData ? (
                <IaqInsightGraph
                    usageHours={usageHours}
                    chartData={sensorGraphData}
                    ranges={sensorRanges.ranges}
                    unit={sensorRanges.unit}
                    sensor={sensor}
                    from={from}
                    to={to}
                    graphId={`${sensor}-sneakPeak-${serialNumber}`}
                    timeZone={timeZone}
                />
            ) : (
                <div className="centered">
                    <div className="centered__content">
                        <StandAloneSpinner />
                    </div>
                </div>
            )}
        </div>
    );

    const tableRow = (): React.ReactElement => {
        const rowWithData = average !== undefined;
        if (rowWithData) {
            return (
                <div
                    role="button"
                    onKeyUp={(e: KeyboardEvent<HTMLDivElement>): void | false =>
                        e.key === 'Enter' && clickSneakPeak(serialNumber)
                    }
                    tabIndex={0}
                    onClick={(): void => clickSneakPeak(serialNumber)}
                    className="insight-table__row insight-table__row--clickable"
                    key={`${serialNumber}-${sensor}`}
                >
                    {rowContent}
                </div>
            );
        }
        return (
            <div className="insight-table__row" key={`row-${serialNumber}-${sensor}`}>
                {rowContent}
            </div>
        );
    };

    if (showSneakPeak) {
        return (
            <>
                {tableRow()}
                {sneakPeakMode}
            </>
        );
    }

    return tableRow();
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        deviceSensorData: { sensorData },
    } = state;
    return {
        sensorData,
    };
};

export default connect(mapStateToProps)(InsightTableRowComponent);
