import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { Group } from 'commons/src/models/commonTypeScript';
import { getOrganizationLogo } from '../../../actions/organizationPropertiesActions';
import { Store } from '../../../reducers';
import EditOrganizationProfile from './EditOrganizationProfile';

export type Props = {
    selectedGroup?: Group;
    logoImage?: string;
    getUserSettingsLoading: boolean;
    getLogoLoading: boolean;
};

export const OrganizationProfileComponent = ({
    selectedGroup,
    logoImage,
    getUserSettingsLoading,
    getLogoLoading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const toggleEditing = (): void => setIsEditing(!isEditing);

    const userRole: Role = useSelector((store: Store) => store.userSettings.selectedGroup?.role || Role.VIEWER);

    useEffect((): void => {
        dispatch(getOrganizationLogo());
    }, []);

    return (
        <ReactPlaceholder ready={!getUserSettingsLoading && !getLogoLoading} customPlaceholder={mediumFormLoader}>
            <h2 className="settings__header">{txt('OrganizationProfile.OrganizationProfile')}</h2>
            <div className="settings-details-container">
                {isEditing ? (
                    <EditOrganizationProfile onClose={toggleEditing} />
                ) : (
                    <div>
                        <p className="text-large">
                            {txt('OrganizationProfile.Name')}
                            <span className="text-bold" id="organizationName">
                                {selectedGroup && selectedGroup.organizationName}
                            </span>
                        </p>
                        <div className="settings__row">
                            <div className="form__attr form__attr--edit-image--logo form__attr--image form__attr--image--medium no-margin">
                                {logoImage ? (
                                    <img src={logoImage} alt={txt('OrganizationProfile.LogoImage')} />
                                ) : (
                                    <div className="form__attr--image__placeholder">
                                        <MaterialIcon extraClass="building-tile__image__icon" name="photo" />
                                        <br />
                                        {txt('OrganizationProfile.NoLogoUploaded')}
                                    </div>
                                )}
                            </div>
                            <div className="text-small">{txt('OrganizationProfile.LogoIsUsed')}</div>
                        </div>
                        {userRoleAboveRequiredLevel(userRole, RequiredRoleLevel.ABOVE_ADMIN) && (
                            <div className="settings__row">
                                <PrimaryButton
                                    id="editOrgProfileButton"
                                    title="EditProfile"
                                    onClick={toggleEditing}
                                    color="secondary"
                                    icon={<MaterialIcon name="edit" />}
                                    testId="edit-org-profile-button"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ReactPlaceholder>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { selectedGroup, loading: getUserSettingsLoading },
        organizationProperties: { logoImage },
        requests: {
            GET_ORGANIZATION_LOGO: { loading: getLogoLoading },
        },
    } = store;

    return {
        selectedGroup,
        getUserSettingsLoading,
        logoImage,
        getLogoLoading,
    };
};

export default connect(mapStateToProps)(OrganizationProfileComponent);
