import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchComponent from '../input/SearchComponent';
import MaterialIcon from '../MaterialIcon';
import styles from './ChangeSelectedSearchHeader.module.scss';

type Props = {
    setSearchText: (searchText: string) => void;
    displaySearch: boolean;
    headerText: string;
    headerTextWithIcon: boolean;
};

export const ChangeSelectedSearchHeaderComponent = (props: Props): React.ReactElement => {
    const { displaySearch, setSearchText, headerText, headerTextWithIcon } = props;
    const { t: txt } = useTranslation();

    const searchUpdate = (text: string): void => {
        const lowerCaseSearch = text.trim().toLowerCase();
        setSearchText(lowerCaseSearch);
    };

    return (
        <div className={displaySearch ? styles.searchHeader : styles.header}>
            {displaySearch ? (
                <div className={styles.search}>
                    <SearchComponent onChange={searchUpdate} />
                </div>
            ) : (
                <h5 className={styles.title}>
                    {headerTextWithIcon && <MaterialIcon name="swap_horizontal_circle" />}
                    {txt(headerText)}
                </h5>
            )}
        </div>
    );
};

export default ChangeSelectedSearchHeaderComponent;
