import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedGroup, groupDisplayName } from '../../features/group';
import { GroupType } from '../../models/commonEnums';
import { Group, SelectedGroup } from '../../models/commonTypeScript';
import CircleButton from '../buttons/CircleButton';
import styles from './ChangeAccountGroupList.module.scss';

export type Props = {
    groupsOfType: Group[];
    groupHeader: string;
    selectGroup: (selectedGroup: SelectedGroup) => void;
    selectedGroup?: Group;
    userName?: string;
    loading: boolean;
    topLine: boolean;
};

export const groupName = (group?: Group): string => (group ? group.organizationName || group.groupName || '' : '');

export const ChangeAccountGroupListComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { groupsOfType, groupHeader, selectGroup, selectedGroup, loading, topLine, userName } = props;
    const groupActive = (userGroupId?: string): boolean => !!selectedGroup && selectedGroup.id === userGroupId;

    const singleConsumer = groupsOfType.filter(g => g.groupType === GroupType.consumer).length === 1;

    const sortedList = groupsOfType.sort((group1, group2) =>
        groupDisplayName(group1, singleConsumer, userName).localeCompare(
            groupDisplayName(group2, singleConsumer, userName)
        )
    );

    const setOrganization = (group: Group): void => {
        selectGroup(getSelectedGroup(group));
    };

    return (
        <>
            {topLine && <div className={styles.sectionLine} />}
            <h6 className={styles.sectionHeader}>{txt(groupHeader)}</h6>
            <div className={styles.list}>
                {sortedList.map(group => (
                    <div className={styles.listRow} key={`organization-row-${group.id}`}>
                        <div className={styles.listRowText}>
                            <div className={styles.textOverflow}>
                                {groupDisplayName(group, singleConsumer, userName)}
                            </div>
                            {group.groupType !== GroupType.consumer && (
                                <div className={styles.textOverflow}>{txt(`AuthorizationRole.${group.role}`)}</div>
                            )}
                        </div>
                        <CircleButton
                            onClick={(): void => setOrganization(group)}
                            testAttr={`swap-organization-${group.id.toLowerCase()}`}
                            iconName={groupActive(group.id) ? 'check' : 'arrow_forward'}
                            color={groupActive(group.id) ? 'confirmed' : 'secondary'}
                            loading={loading && groupActive(group.id)}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default ChangeAccountGroupListComponent;
