import React from 'react';
import { useTranslation } from 'react-i18next';
import IconWarning from '../../img/icon-warning';
import PrimaryButton from '../buttons/PrimaryButton';
import PageWrapper from '../containers/PageWrapper';
import styles from './Error.module.scss';

type Props = {
    errorText?: string;
};

const Error = ({ errorText }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const isOnline = navigator.onLine;
    const translatedError = errorText ? txt(`ErrorCodes.${errorText}`) : txt('ErrorSubtitle');
    return (
        <PageWrapper pageType="medium">
            <div className={styles.section}>
                <IconWarning className={styles.icon} />
                <p className={styles.header}>{txt(isOnline ? 'ErrorTitle' : 'ErrorHandling.YourAreNotOnline')}</p>
                <p className={styles.subHeader}>
                    {isOnline ? translatedError : txt('ErrorHandling.ConnectToTheInternetAndTryAgain')}
                </p>
                <p>
                    <PrimaryButton
                        color="secondary"
                        id="404TryAgainButton"
                        title={isOnline ? 'TryAgain' : 'ErrorHandling.RefreshPage'}
                        loading={false}
                        onClick={(): void => {
                            window.location.reload();
                        }}
                        testId="error-try-again"
                    />
                </p>
            </div>
        </PageWrapper>
    );
};

export default Error;
