import moment from 'moment';
import { all, call, put, takeEvery, CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { fetchVirtualDeviceData } from 'commons/src/api/segment';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { DashboardSensorData, DeviceResponse } from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import { createChartData } from 'commons/src/sagas/DashboardSagas/fetchDashboardSensorTile';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    BuildingOptimizationActionType,
    GetHeatingCoolingData,
    GetHeatingCoolingDataSuccess,
    getHeatingCoolingDataSuccess,
    GetPresenceData,
    getPresenceDataSuccess,
    GetPresenceDataSuccess,
    GetPresenceSensorData,
    GetPresenceSensorDataSuccess,
    getPresenceSensorDataSuccess,
    GetPresenceWeekAggregation,
    getPresenceWeekAggregationSuccess,
    GetPresenceWeekAggregationSuccess,
} from '../actions/buildingOptimizationActions';
import {
    getHeatingAndCoolingOptimizationData,
    getPresenceInsightData,
    getPresenceWeekAggregationData,
} from '../api/buildingApi';
import {
    HeatingCoolingData,
    PresenceByDevice,
    PresenceOverTime,
    PresenceWeekAggregation,
} from '../models/buildingModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetHeatingCoolingOptimizationDataSagaReturnType = Generator<
    CallEffect<{ results: HeatingCoolingData }> | PutEffect<GetHeatingCoolingDataSuccess> | RequestActions,
    void,
    { results: HeatingCoolingData }
>;
export function* getHeatingCoolingOptimizationDataSaga({
    payload,
}: GetHeatingCoolingData): GetHeatingCoolingOptimizationDataSagaReturnType {
    try {
        const response = yield call(getHeatingAndCoolingOptimizationData, payload.locationId, payload.from, payload.to);
        yield put(getHeatingCoolingDataSuccess(response.results, payload));
        yield put(requestSuccess(RequestType.GetHeatingCoolingData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetHeatingCoolingData, RequestActionType.Error));
    }
}

type GetPresenceInsightDataSagaReturnType = Generator<
    | CallEffect<{ response: { presenceOverTime: PresenceOverTime[]; presenceByDevice: PresenceByDevice[] } }>
    | PutEffect<GetPresenceDataSuccess>
    | RequestActions,
    void,
    { response: { presenceOverTime: PresenceOverTime[]; presenceByDevice: PresenceByDevice[] } }
>;
export function* getPresenceInsightDataSaga({ payload }: GetPresenceData): GetPresenceInsightDataSagaReturnType {
    try {
        const { response } = yield call(getPresenceInsightData, payload);
        yield put(getPresenceDataSuccess(response, payload));
        yield put(requestSuccess(RequestType.GetPresenceData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetPresenceData, RequestActionType.Error));
    }
}

type GetPresenceWeekAggregationSagaReturnType = Generator<
    CallEffect<{ response: PresenceWeekAggregation }> | PutEffect<GetPresenceWeekAggregationSuccess> | RequestActions,
    void,
    { response: PresenceWeekAggregation }
>;
export function* getPresenceWeekAggregationSaga({
    payload,
}: GetPresenceWeekAggregation): GetPresenceWeekAggregationSagaReturnType {
    try {
        const { response } = yield call(getPresenceWeekAggregationData, payload.locationId, payload.filter);
        yield put(getPresenceWeekAggregationSuccess({ ...response, locationId: payload.locationId }));
        yield put(requestSuccess(RequestType.GetPresenceWeekAggregation, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetPresenceWeekAggregation, RequestActionType.Error));
    }
}

type GetPresenceSensorDataSagaType = Generator<
    CallEffect<DeviceResponse> | SelectEffect | PutEffect<GetPresenceSensorDataSuccess> | RequestActions,
    void,
    DeviceResponse & DashboardSensorData
>;

export const getFromAndTo = (fromDate: string, toDate: string): { from: string; to: string } => {
    if (!fromDate) throw Error('EndDate is required when fetching segment');
    const to = toDate && toDate.slice(0, toDate.lastIndexOf('.'));
    const from = fromDate.slice(0, fromDate.lastIndexOf('.'));
    return { from, to };
};

export function* getPresenceSensorData({ payload }: GetPresenceSensorData): GetPresenceSensorDataSagaType {
    const { serialNumber, sensor, viewType, fromDate, toDate, resolution } = payload;
    const { from, to } = getFromAndTo(fromDate, toDate);

    try {
        const response = yield call(fetchVirtualDeviceData, serialNumber, {
            from,
            to,
            resolution,
            sensors: sensor,
        });
        const segmentStartTime = moment.utc(response.segmentStart).valueOf();

        const presenceSensor = response.sensors.find(sensorResponse => sensorResponse.type === SensorTypes.presence);
        const sensorDetails = createChartData(response, presenceSensor, segmentStartTime);

        yield put(
            getPresenceSensorDataSuccess({
                sensorData: sensorDetails,
                serialNumber,
                viewType,
            })
        );
        yield put(requestSuccess(RequestType.GetPresenceSensorData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        requestError(errorAsErrorType, RequestType.GetPresenceSensorData, RequestActionType.Error);
    }
}

export default function* buildingOptimizationSaga(): Generator {
    yield all([
        takeEvery(BuildingOptimizationActionType.GetHeatingCoolingDataInit, getHeatingCoolingOptimizationDataSaga),
        takeEvery(BuildingOptimizationActionType.GetPresenceDataInit, getPresenceInsightDataSaga),
        takeEvery(BuildingOptimizationActionType.GetPresenceWeekAggregationInit, getPresenceWeekAggregationSaga),
        takeEvery(BuildingOptimizationActionType.GetPresenceSensorDataInit, getPresenceSensorData),
    ]);
}
