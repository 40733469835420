import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDotColorFromRating, sensorRating } from 'commons/src/commonFunctions';
import { SensorStatusCircleWithIcon } from 'commons/src/components/sensors/SensorStatusDot';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { IaqInsightData, ReportSensorThresholds } from '../../../models/common';

type Props = {
    sensor: SensorTypes;
    sensorReports: { [sensor: string]: IaqInsightData };
    thresholds: { [sensor: string]: ReportSensorThresholds };
};
export const SensorAverageComponent = (props: Props): React.ReactElement => {
    const { sensor, sensorReports, thresholds } = props;
    const { t: txt } = useTranslation();

    const sensorDetails: IaqInsightData = sensorReports[sensor];
    const value = sensorDetails.averageValueWithinOpeningHours;
    const sensorThreshold = thresholds[sensor];
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const valueRange: Rating | undefined = sensorRating(sensorThreshold.ranges, value);
    const color = getDotColorFromRating(valueRange);
    const displayedUnit = sensorUnits[sensorThreshold.unit];
    const displayedValue = value && (displayedUnit === sensorUnits.pci ? value.toFixed(1) : value.toFixed(0));
    return (
        <div
            key={`${sensor}-average`}
            className="insight-tile__content insight-tile__content--sensor insight-tile__content--sensor--padding-bottom"
        >
            <SensorStatusCircleWithIcon color={color} sensor={sensor} />
            <div className="sensor__data">
                <span className="insight-sensor__value">
                    {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    <span className="sensor__unit">{displayedUnit}</span>
                </span>
                <span className="insight-sensor__sensor">{txt(sensor)}</span>
            </div>
        </div>
    );
};

export default SensorAverageComponent;
