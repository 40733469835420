import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { statusColor } from 'commons/src/commonFunctions';
import {
    ColoredCircle,
    DeviceIcon,
    SensorUnitText,
    coloredCircleStyles,
    sensorIconSource,
    InsightWrapper,
} from 'commons/src/components/PDF/PdfConstants';
import { sensorUnits } from 'commons/src/constants';
import { PDFContext, ReportSensorThresholds } from '../../../../models/common';

const componentStyles = StyleSheet.create({
    valueWrapper: {
        paddingLeft: '2vw',
        paddingRight: '2vw',
    },
    sensorValueWrapper: {
        fontSize: '10',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    sensorValue: {
        fontSize: '18',
        fontFamily: 'DemiBold',
    },
    sensorNameText: {
        fontSize: '15',
    },
});

type SensorResultProps = {
    txt: PDFContext;
    value: number;
    thresholds: ReportSensorThresholds;
    sensor: string;
};

const ResultSummary = (props: SensorResultProps): React.ReactElement => {
    const { txt, value, thresholds, sensor } = props;
    const { unit } = thresholds;
    const color = statusColor(thresholds.ranges, value);
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const displayedValue =
        sensorUnits[unit] === sensorUnits.pci ? value && value.toFixed(1) : value && value.toFixed(0);
    const sensorReplacedSubText = txt(sensor).replace('CO₂', 'CO2');
    return (
        <InsightWrapper>
            <ColoredCircle style={[coloredCircleStyles[color as keyof typeof coloredCircleStyles]]}>
                <DeviceIcon src={sensorIconSource(sensor) || ''} />
            </ColoredCircle>
            <View style={componentStyles.valueWrapper}>
                <View style={componentStyles.sensorValueWrapper}>
                    <Text style={componentStyles.sensorValue}>
                        {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    </Text>
                    <SensorUnitText>{(unit && sensorUnits[unit]) || ''}</SensorUnitText>
                </View>
                <Text style={componentStyles.sensorNameText}>{sensorReplacedSubText}</Text>
            </View>
        </InsightWrapper>
    );
};

export default ResultSummary;
