import React from 'react';
import CustomSlider from 'commons/src/components/slider/CustomSlider';
import { sensorUnits } from 'commons/src/constants';

export interface Config {
    recommended: { min: number; max: number };
    range: { min: number; max: number; step: number };
    unit: keyof typeof sensorUnits;
    descriptionKey: string;
}

export type Props = {
    config: Config;
    onChange: (thresholdValue: number[]) => void;
    value: number[];
};

const ThresholdSlider = ({ value, onChange, config }: Props): React.ReactElement => {
    const updateThresholds = (e: Event, thresholdValue: number[]): void => {
        onChange(thresholdValue);
    };
    return (
        <CustomSlider
            value={value}
            onChange={updateThresholds}
            size="small"
            min={config.range.min}
            max={config.range.max}
            step={config.range.step}
            valueLabelDisplay="on"
            aria-label="test"
            marks={[
                { value: config.recommended.min, label: config.recommended.min.toString() },
                { value: config.recommended.max, label: config.recommended.max.toString() },
            ]}
        />
    );
};

export default ThresholdSlider;
