import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from '../../reducers';
import styles from './CookieBanner.module.scss';

const DemoBanner = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { demoMode } = useSelector((state: Store) => state.userSettings);
    if (!demoMode) {
        return <div />;
    }
    return (
        <div className={styles.banner}>
            <span className={styles.message}>{txt('DemoMode')}</span>
        </div>
    );
};

export default DemoBanner;
