import React from 'react';
import { useTranslation } from 'react-i18next';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { InletAirMetaDataProperties } from 'commons/src/models/commonTypeScript';
import { defaultInletAirConfig, inletAirConfigurations } from './inletAirValidation';
import ThresholdWrapper from './ThresholdWrapper';

export type Props = {
    inletAirConfig: InletAirMetaDataProperties;
    setInletAirConfig: (config: InletAirMetaDataProperties) => void;
};

const InletAirConfigurationComponent = ({ inletAirConfig, setInletAirConfig }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const updateInletAirThreshold = (sensorType: SensorTypes, newValues: number[]): void => {
        if (sensorType === SensorTypes.co2) {
            setInletAirConfig({
                ...inletAirConfig,
                inletMinCo2: newValues[0],
                inletMaxCo2: newValues[1],
            });
        }
        if (sensorType === SensorTypes.temp) {
            setInletAirConfig({
                ...inletAirConfig,
                inletMinTemp: newValues[0],
                inletMaxTemp: newValues[1],
            });
        }
    };
    const updateInletAirEnabled = (sensorType: SensorTypes, enabled: boolean): void => {
        if (sensorType === SensorTypes.co2) setInletAirConfig({ ...inletAirConfig, inletEnableCo2: enabled });
        if (sensorType === SensorTypes.temp) setInletAirConfig({ ...inletAirConfig, inletEnableTemp: enabled });
    };

    const { inletMaxCo2, inletMinCo2, inletEnableCo2, inletMinTemp, inletMaxTemp, inletEnableTemp } = {
        ...defaultInletAirConfig,
        ...inletAirConfig,
    };

    return (
        <>
            <div className="form__field form__field--single-width labeled-text">
                <span className="labeled-text__label">{txt(`InletAirConfigDescription`)}</span>
            </div>
            <ThresholdWrapper
                sensorType={SensorTypes.co2}
                config={inletAirConfigurations[SensorTypes.co2]}
                setEnabledThreshold={updateInletAirEnabled}
                enabled={inletEnableCo2}
                setThresholdValue={updateInletAirThreshold}
                thresholds={[inletMinCo2, inletMaxCo2]}
            />
            <ThresholdWrapper
                sensorType={SensorTypes.temp}
                config={inletAirConfigurations[SensorTypes.temp]}
                setEnabledThreshold={updateInletAirEnabled}
                enabled={inletEnableTemp}
                setThresholdValue={updateInletAirThreshold}
                thresholds={[inletMinTemp, inletMaxTemp]}
            />
        </>
    );
};

export default InletAirConfigurationComponent;
