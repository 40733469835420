import {
    FETCH_IAQ_INSIGHT_DATA_SUCCESS,
    FetchIaqInsightData,
    FetchIaqInsightDataSuccess,
    InsightActionType,
} from '../actions/insightActions';
import { IaqInsightData, ReportSensorThresholds } from '../models/common';

export type IaqInsightState = {
    locationId: string | undefined;
    sensorReports: { [sensor: string]: IaqInsightData };
    reportId: string;
    includeLogo: boolean;
    serialNumbers: string[];
    thresholds: { [sensor: string]: ReportSensorThresholds };
};

const initialState: IaqInsightState = {
    locationId: undefined,
    sensorReports: {},
    reportId: '',
    includeLogo: false,
    serialNumbers: [],
    thresholds: {},
};

export default (
    state: IaqInsightState = initialState,
    action: FetchIaqInsightData | FetchIaqInsightDataSuccess
): IaqInsightState => {
    switch (action.type) {
        case InsightActionType.FetchIaqInsightDataInit:
            return {
                ...state,
                includeLogo: action.payload.includeLogo || false,
            };
        case FETCH_IAQ_INSIGHT_DATA_SUCCESS:
            return {
                ...state,
                locationId: action.payload.locationId,
                sensorReports: action.payload.sensorReports,
                reportId: action.payload.reportId,
                includeLogo: action.payload.includeLogo || false,
                serialNumbers: action.payload.serialNumbers,
                thresholds: action.payload.thresholds,
            };
        default:
            return state;
    }
};
