import { RequestActionType } from 'commons/src/actions/requestActions';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import {
    HeatingCoolingData,
    PresenceByDevice,
    PresenceOverTime,
    PresenceRequest,
    PresenceWeekAggregation,
} from '../models/buildingModels';
import { BusinessActionRequestType } from '../models/common';
import { PresenceViewType } from '../models/commonEnums';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum BuildingOptimizationActionType {
    GetHeatingCoolingDataInit = 'GET_HEATING_COOLING_DATA/INIT',
    GetHeatingCoolingDataSuccess = 'GET_HEATING_COOLING_DATA_SUCCESS',
    GetPresenceDataInit = 'GET_PRESENCE_DATA/INIT',
    GetPresenceDataSuccess = 'GET_PRESENCE_DATA_SUCCESS',
    GetPresenceWeekAggregationInit = 'GET_PRESENCE_WEEK_AGGREGATION/INIT',
    GetPresenceWeekAggregationSuccess = 'GET_PRESENCE_WEEK_AGGREGATION_SUCCESS',
    GetPresenceSensorDataInit = 'GET_PRESENCE_SENSOR_DATA/INIT',
    GetPresenceSensorDataSuccess = 'GET_PRESENCE_SENSOR_DATA_SUCCESS',
}

export interface GetHeatingCoolingData extends BusinessActionRequestType {
    type: BuildingOptimizationActionType.GetHeatingCoolingDataInit;
    payload: { name: TimePeriod; from: string; to: string; locationId: string; resolution: Resolution };
}

export const getHeatingCoolingData = (payload: {
    from: string;
    to: string;
    name: TimePeriod;
    locationId: string;
    resolution: Resolution;
}): GetHeatingCoolingData => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetHeatingCoolingData,
    type: BuildingOptimizationActionType.GetHeatingCoolingDataInit,
    payload,
});

export interface GetHeatingCoolingDataSuccess {
    type: BuildingOptimizationActionType.GetHeatingCoolingDataSuccess;
    payload: HeatingCoolingData;
    selectedPeriod: { from: string; to: string; name: TimePeriod; locationId: string; resolution: Resolution };
}

export const getHeatingCoolingDataSuccess = (
    payload: HeatingCoolingData,
    selectedPeriod: { from: string; to: string; name: TimePeriod; locationId: string; resolution: Resolution }
): GetHeatingCoolingDataSuccess => ({
    type: BuildingOptimizationActionType.GetHeatingCoolingDataSuccess,
    payload,
    selectedPeriod,
});

export interface GetPresenceData extends BusinessActionRequestType {
    type: BuildingOptimizationActionType.GetPresenceDataInit;
    payload: {
        locationId: string;
        toDate: string;
        fromDate: string;
        openingHours: boolean;
        filter: { floor: string[]; roomType: string[] };
    };
}

export const getPresenceData = (payload: PresenceRequest): GetPresenceData => ({
    type: BuildingOptimizationActionType.GetPresenceDataInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetPresenceData,
    payload,
});

export interface GetPresenceDataSuccess {
    type: BuildingOptimizationActionType.GetPresenceDataSuccess;
    payload: {
        presenceOverTime: PresenceOverTime[];
        presenceByDevice: PresenceByDevice[];
    };
    selectedOptions: PresenceRequest;
}

export const getPresenceDataSuccess = (
    payload: { presenceOverTime: PresenceOverTime[]; presenceByDevice: PresenceByDevice[] },
    selectedOptions: PresenceRequest
): GetPresenceDataSuccess => ({
    type: BuildingOptimizationActionType.GetPresenceDataSuccess,
    payload,
    selectedOptions,
});

export interface GetPresenceWeekAggregation extends BusinessActionRequestType {
    type: BuildingOptimizationActionType.GetPresenceWeekAggregationInit;
    payload: {
        locationId: string;
        filter: { floor: string[]; roomType: string[] };
    };
}

export const getPresenceWeekAggregation = (payload: {
    locationId: string;
    filter: { floor: string[]; roomType: string[] };
}): GetPresenceWeekAggregation => ({
    type: BuildingOptimizationActionType.GetPresenceWeekAggregationInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetPresenceWeekAggregation,
    payload,
});

export interface GetPresenceWeekAggregationSuccess {
    type: BuildingOptimizationActionType.GetPresenceWeekAggregationSuccess;
    payload: PresenceWeekAggregation;
}

export const getPresenceWeekAggregationSuccess = (
    payload: PresenceWeekAggregation
): GetPresenceWeekAggregationSuccess => ({
    type: BuildingOptimizationActionType.GetPresenceWeekAggregationSuccess,
    payload,
});

type PresenceSensorDataPayload = {
    resolution?: Resolution;
    serialNumber: string;
    viewType: PresenceViewType;
    sensor: string;
    fromDate: string;
    toDate: string;
};
export interface GetPresenceSensorData extends BusinessActionRequestType {
    type: BuildingOptimizationActionType.GetPresenceSensorDataInit;
    payload: PresenceSensorDataPayload;
}
export const getPresenceSensorData = (payload: PresenceSensorDataPayload): GetPresenceSensorData => ({
    type: BuildingOptimizationActionType.GetPresenceSensorDataInit,
    requestType: RequestType.GetPresenceSensorData,
    requestActionType: RequestActionType.Init,
    payload,
});

type SensorResponse = {
    averageValue: number | undefined;
    chartData: number[][];
    minValue: number | undefined;
};

export interface GetPresenceSensorDataSuccess {
    type: BuildingOptimizationActionType.GetPresenceSensorDataSuccess;
    sensorData: SensorResponse;
    serialNumber: string;
    viewType: PresenceViewType;
}

export const getPresenceSensorDataSuccess = (payload: {
    sensorData: SensorResponse;
    serialNumber: string;
    viewType: PresenceViewType;
}): GetPresenceSensorDataSuccess => ({
    type: BuildingOptimizationActionType.GetPresenceSensorDataSuccess,
    sensorData: payload.sensorData,
    serialNumber: payload.serialNumber,
    viewType: payload.viewType,
});

export type BuildingOptimizationActions =
    | GetHeatingCoolingDataSuccess
    | GetPresenceDataSuccess
    | GetPresenceWeekAggregationSuccess
    | GetPresenceSensorDataSuccess;
