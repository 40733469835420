import React, { useEffect, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logOut } from '../../actions/LoginAndRegisterActions';
import { navigationBreakWidth } from '../../constants';
import { Store } from '../../reducers';
import MaterialIcon from '../MaterialIcon';
import HamburgerIcon, { hamburgerTargetIds } from './HamburgerIcon';
import HamburgerMenu from './HamburgerMenu';
import NavigationItem from './NavigationItem';
import NoSubscription from './NoSubscription';
import ProfileSettings from './ProfileSettings';

export type PassedProps = {
    navigationItems: Array<{
        id: string;
        key: string;
        path: string;
        title: string;
        iconName: string;
        extraClass?: string;
    }>;
    logo: string;
    slimLogo: string;
    styleClass: string;
    poweredByLogo?: string;
};

type StateProps = {
    userName: string;
    email: string;
};

type Props = PassedProps & StateProps;

export const NavigationSidebarComponent = ({
    navigationItems,
    logo,
    slimLogo,
    styleClass,
    poweredByLogo,
    userName,
    email,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [navigationType, setNavigationType] = useState('slim');
    const [burgerOpen, setBurgerState] = useState(false);

    const onLogOut = (): void => {
        dispatch(logOut());
    };
    const navItems = (): React.ReactElement[] =>
        navigationItems.map(item => (
            <NavigationItem
                id={item.id}
                key={item.key}
                path={item.path}
                title={item.title}
                iconName={item.iconName}
                extraClass={item.extraClass}
                navigationType={burgerOpen ? 'large' : navigationType}
            />
        ));

    const setMenuType = (): void => {
        let newNavType;
        if (window.innerWidth <= navigationBreakWidth.hamburger && navigationType !== 'hamburger') {
            newNavType = 'hamburger';
            setBurgerState(false);
        } else if (
            window.innerWidth > navigationBreakWidth.hamburger &&
            window.innerWidth <= navigationBreakWidth.slim &&
            navigationType !== 'slim'
        ) {
            newNavType = 'slim';
        } else if (window.innerWidth > navigationBreakWidth.slim && navigationType !== 'large') {
            newNavType = 'large';
            setBurgerState(false);
        }
        if (newNavType && newNavType !== navigationType) {
            setNavigationType(newNavType);
        }
    };

    const outsideClickListener = (e: MouseEvent<HTMLElement>): void => {
        // this needs to be target - not currentTarget as this will not include the classNames
        if (e.target && !hamburgerTargetIds.includes(e.target.id)) {
            window.removeEventListener('click', outsideClickListener);
            setBurgerState(false);
            setNavigationType('slim');
        }
    };

    const burgerToggle = (): void => {
        setBurgerState(!burgerOpen);
        if (burgerOpen) {
            window.removeEventListener('click', outsideClickListener);
            setMenuType();
        } else {
            setNavigationType('large');
            window.addEventListener('click', outsideClickListener);
        }
    };

    useEffect((): void => {
        setMenuType();
    }, []);

    useEffect((): (() => void) => {
        window.addEventListener('resize', setMenuType);
        return (): void => window.removeEventListener('resize', setMenuType);
    }, [setMenuType]);

    const displayMenuIcon = burgerOpen || navigationType === 'slim';
    const displayLogo = !(!burgerOpen && navigationType === 'slim');

    return navigationType === 'hamburger' ? (
        <HamburgerMenu navigationItems={navigationItems} />
    ) : (
        <div>
            <div className={classNames({ 'sidebar-navigation__overlay': burgerOpen })}> </div>
            <div
                className={classNames('sidebar-navigation', {
                    'sidebar-navigation--slim': navigationType === 'slim',
                    'sidebar-navigation--open': burgerOpen,
                    [styleClass]: !!styleClass,
                })}
            >
                <div className="sidebar-navigation__header">
                    {displayMenuIcon && <HamburgerIcon open={burgerOpen} onClick={burgerToggle} />}
                    {displayLogo && (
                        <NavLink className="sidebar-navigation__logo-wrapper" to="/">
                            <img
                                src={navigationType === 'slim' ? slimLogo : logo}
                                className={classNames('sidebar-navigation__logo', {
                                    'sidebar-navigation__logo--open': displayMenuIcon,
                                })}
                                alt={txt('BackToDashboard')}
                            />
                        </NavLink>
                    )}
                </div>
                <ProfileSettings navigationType={navigationType} />
                <NoSubscription navigationType={navigationType} />
                <nav className="sidebar-navigation__items-container">{navItems()}</nav>
                <div className="sidebar-navigation__items-container__item--function-item--border-top">
                    {navigationType !== 'slim' && (
                        <div className="sidebar-navigation__profile__info">
                            <div className="sidebar-navigation__profile__info__text sidebar-navigation__profile__info__text--bold">
                                {userName}
                            </div>
                            <div className="sidebar-navigation__profile__info__text">{email}</div>
                        </div>
                    )}
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyUp={(e): void => {
                            if (e.key === 'enter') onLogOut();
                        }}
                        onClick={onLogOut}
                        data-testid="logout-button"
                        data-logout-button
                        className={classNames(
                            'sidebar-navigation__items-container__item',
                            'sidebar-navigation__items-container__item--center',
                            'sidebar-navigation__items-container__item--function-item'
                        )}
                    >
                        <li className="sidebar-navigation__items-container__element">
                            <MaterialIcon
                                extraClass="sidebar-navigation__items-container__item__icon"
                                name="exit_to_app"
                            />
                            <span
                                className={classNames({
                                    'sidebar-navigation__items-container__item--slim': navigationType === 'slim',
                                })}
                            >
                                {txt('Logout')}
                            </span>
                        </li>
                    </div>
                </div>
                {poweredByLogo && navigationType === 'large' && (
                    <div className="sidebar-navigation__items-container__item sidebar-navigation__items-container__item--powered-by">
                        {txt('PoweredBy')}
                        <img
                            src={poweredByLogo}
                            className="sidebar-navigation__items-container__item--powered-by__logo"
                            alt={txt('AirthingsLogo')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { userName, email },
    } = state;

    return {
        userName,
        email,
    };
};

export default connect(mapStateToProps)(NavigationSidebarComponent);
