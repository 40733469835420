import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useSelector } from 'react-redux';
import { fullListPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import BatteryComponent from 'commons/src/components/sensors/BatteryComponent';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { HealthStatusIssueTableRow } from '../../../models/buildingModels';
import RequestType from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import styles from './CardIssueTable.module.scss';

type TableProps = {
    headers: { key: keyof HealthStatusIssueTableRow; headerName: string }[];
    rows: HealthStatusIssueTableRow[];
};

const CardIssueTable = ({ headers, rows }: TableProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const { loading, error } = useSelector(
        (state: RootState) => state.requests[RequestType.FetchHealthStatusIssueData]
    );

    if (error && !loading) {
        return <ResponseBox text={txt(`ErrorCodes.${error.error}`)} subtext={txt('SomethingWentWrongTryAgain')} />;
    }

    return (
        <ReactPlaceholder ready={!loading} customPlaceholder={fullListPlaceholder}>
            <table className={styles.tableContainer}>
                <thead>
                    <tr className={styles.headerRow}>
                        {headers.map(header => (
                            <th key={header.key} className={styles.headerCell}>
                                {txt(header.headerName)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(device => (
                        <React.Fragment key={device.serialNumber}>
                            <tr className={styles.dataRow}>
                                {headers.map(header => (
                                    <td key={header.key} className={styles.standardCell}>
                                        {header.key === 'battery' ? (
                                            <BatteryComponent
                                                batteryPercentage={parseInt(device[header.key] as string, 10)}
                                                healthStatus={DeviceHealthStatus.online}
                                            />
                                        ) : (
                                            device[header.key]
                                        )}
                                    </td>
                                ))}
                            </tr>
                            {device.isLast && (
                                <tr className={styles.separatorRow}>
                                    <td colSpan={headers.length} />
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </ReactPlaceholder>
    );
};

export default CardIssueTable;
