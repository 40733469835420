import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroup } from '../../actions/selectGroupActions';
import { analyticsLogger, mixpanelRegisterSuperProperties } from '../../analytics';
import { ACCOUNT_SWAPPED_ACCOUNT } from '../../analytics/AnalyticsEvents';
import { groupName } from '../../features/group';
import { GroupType } from '../../models/commonEnums';
import { SelectedGroup } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import PrimaryButton from '../buttons/PrimaryButton';
import ModalWrapper from '../modals/ModalWrapper';
import ChangeAccountGroupList from './ChangeAccountGroupList';
import styles from './ChangeOrganizationModal.module.scss';
import ChangeSelectedSearchHeader from './ChangeSelectedSearchHeader';

export type Props = {
    onClose: () => void;
};

export const ChangeOrganizationModalComponent = ({ onClose }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const { userName, groups, loading, selectedGroup } = useSelector((state: Store) => state.userSettings);
    const filteredGroups = groups.filter(group => {
        const inName = !!group.groupName && group.groupName.toLowerCase().includes(searchText.toLowerCase());
        const inOrgName =
            group.organizationName && group.organizationName.toLowerCase().includes(searchText.toLowerCase());
        const inRole =
            group.role && txt(`AuthorizationRole.${group.role}`).toLowerCase().includes(searchText.toLowerCase());
        const inUserName =
            group.groupType === GroupType.consumer && userName.toLowerCase().includes(searchText.toLowerCase());
        return inName || inOrgName || inRole || inUserName;
    });
    const organizations = filteredGroups.filter(group => group.groupType === GroupType.business);
    const consumerAccounts = filteredGroups.filter(group => group.groupType === GroupType.consumer);
    const partnerOrgs = filteredGroups.filter(group => group.groupType === GroupType.partner);

    const setSelectedGroup = (newSelectedGroup: SelectedGroup): void => {
        dispatch(selectGroup(newSelectedGroup));
        const isOrganizationAccount = selectedGroup && selectedGroup.groupType !== GroupType.consumer;

        if (isOrganizationAccount && newSelectedGroup.groupType !== GroupType.consumer) onClose();

        const newlySelectedGroup = groups.find(group => group.id === newSelectedGroup.userGroupId);

        analyticsLogger(ACCOUNT_SWAPPED_ACCOUNT, { organizationName: groupName(newlySelectedGroup) });
        if (newlySelectedGroup) {
            mixpanelRegisterSuperProperties({
                organizationId: newlySelectedGroup.organizationId,
                groupId: newlySelectedGroup.id,
                organization: newlySelectedGroup.organizationName,
                role: newlySelectedGroup.role,
                groupType: newlySelectedGroup.groupType,
                managedBy: newlySelectedGroup.managedByUserGroup,
                name: groupName(newlySelectedGroup),
            });
        }
    };

    return (
        <ModalWrapper size="small" isOpen onClose={onClose} className={styles.modal} customHeader={<div />}>
            <div className={styles.stickySection}>
                <ChangeSelectedSearchHeader
                    setSearchText={setSearchText}
                    headerText="SwapOrganization"
                    displaySearch={groups.length > 5}
                    headerTextWithIcon
                />
                <div className={styles.contentBody}>
                    {consumerAccounts.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={consumerAccounts}
                            groupHeader="Personal"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={false}
                            userName={userName}
                        />
                    )}
                    {partnerOrgs.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={partnerOrgs}
                            groupHeader="Partner.Partner"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={consumerAccounts.length > 0}
                        />
                    )}
                    {organizations.length > 0 && (
                        <ChangeAccountGroupList
                            groupsOfType={organizations}
                            groupHeader="BusinessAccounts"
                            selectGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            loading={loading}
                            topLine={consumerAccounts.length > 0 || partnerOrgs.length > 0}
                        />
                    )}
                </div>
                <div className={styles.buttons}>
                    <PrimaryButton
                        color="secondary"
                        title="Cancel"
                        loading={false}
                        disabled={false}
                        onClick={onClose}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ChangeOrganizationModalComponent;
