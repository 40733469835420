import React, { ReactElement, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment, { unitOfTime } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { colors } from 'commons/src/constants';
import { virtualSensors } from 'commons/src/DeviceAndSensorLists';
import { highChartsTimeZoneOffset } from 'commons/src/features/devicePage/SensorGraph';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { SpaceThresholds } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { buildingSelector } from '../../../../reducers/selectors/buildingSelector';
import { generatePlotBands, addNullPointsToChartData } from '../../../reports/insightFunctions';
import { spaceSensorData } from '../spaceSelectors';
import spaceGraphConfig, { SpaceGraphData } from './spaceGraphConfig';
import getSpaceGraphData from './spaceGraphFunctions';
import styles from './SpaceMainGraph.module.scss';

type Props = {
    spaceId: string;
    sensor: SensorTypes;
    thresholds: { [sensor: string]: SpaceThresholds };
    selectedInterval: SelectedPeriod;
    locationId: string;
    graphStartAndEnd: { max: number; min: number };
    compareSensor?: SensorTypes;
    isFocusView: boolean;
};

const SpaceMainGraph = ({
    sensor,
    selectedInterval,
    spaceId,
    locationId,
    thresholds,
    graphStartAndEnd,
    compareSensor,
    isFocusView,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const {
        spaceData: { sensorData, deviceData, extraSeries },
        requests: spaceDataRequests,
        virtualSensorRequest,
    } = useSelector((state: Store) => spaceSensorData(state, spaceId));

    const { outdoorData, locationId: outdoorLocationId } = useSelector((state: Store) => state.outdoorData);
    const { usageHours, timezone } = useSelector((state: Store) => buildingSelector(state, locationId));

    const sensorThresholds = thresholds[sensor];
    const compareSensorThresholds: SpaceThresholds | undefined = compareSensor && thresholds[compareSensor];

    useEffect((): void => {
        const useUTC = selectedInterval.resolution === Resolution.hour || selectedInterval.resolution === undefined;
        if (timezone) highChartsTimeZoneOffset(timezone, useUTC);
    }, [timezone, selectedInterval]);

    const dateFormat = useSelector((state: Store) => state.userSettings.dateFormat);
    const data: SpaceGraphData[] = useMemo(() => {
        return getSpaceGraphData(
            sensorData,
            extraSeries,
            sensor,
            selectedInterval,
            deviceData,
            false,
            sensorThresholds?.ranges || []
        );
    }, [sensorData, deviceData, sensor, extraSeries, selectedInterval, sensorThresholds]);

    const compareSensorData = useMemo(() => {
        if (!compareSensor || !isFocusView) {
            return [];
        }
        return getSpaceGraphData(
            sensorData,
            {},
            compareSensor,
            selectedInterval,
            deviceData,
            true,
            compareSensorThresholds?.ranges || []
        );
    }, [compareSensor, sensorData, deviceData, sensor, selectedInterval]);

    const calculatedFromDate =
        selectedInterval.period &&
        moment().subtract(selectedInterval.number, selectedInterval.period as unitOfTime.DurationConstructor);
    const fromDate = selectedInterval.startDate || calculatedFromDate;

    const usageHoursPlotBands = useMemo(
        // TODO - update generatePlotBands to use DayJS
        () => (timezone && fromDate && generatePlotBands(usageHours, fromDate, timezone)) || [],
        [timezone, fromDate, usageHours]
    );

    const outdoorSensorData =
        outdoorLocationId === locationId ? outdoorData[selectedInterval.name || '']?.[sensor]?.measurements : [];

    const outdoorGraph = useMemo(
        () => ({
            data: addNullPointsToChartData(
                outdoorSensorData || [],
                selectedInterval.startDate || null,
                selectedInterval.endDate || null
            ),
            name: `${txt('Space.OutdoorSensor', { sensor: txt(sensor) })}`,
            id: `${sensor}-outdoor`,
            color: colors.blueBondi,
        }),
        [outdoorSensorData, selectedInterval]
    );

    const getGraphConfig = (): React.PropsWithoutRef<HighchartsReact.Props> =>
        spaceGraphConfig({
            data:
                outdoorGraph.data.length > 0
                    ? [...data, outdoorGraph, ...compareSensorData]
                    : [...data, ...compareSensorData],
            selectedInterval,
            usageHoursPlotBands,
            dateFormat,
            sensorUnit: sensorThresholds.unit,
            graphStartAndEnd,
            compareSensorUnit: compareSensorThresholds?.unit,
            compareSensorType: compareSensor,
            chartHeight: isFocusView ? 350 : 200,
        });

    const isVirtual = virtualSensors.includes(sensor);
    const dataLoading = isVirtual ? virtualSensorRequest.loading : spaceDataRequests.loading;
    const dataError = isVirtual ? virtualSensorRequest.error : spaceDataRequests.error;

    if (dataError) {
        return <ResponseBox text={txt(`ErrorCodes.${dataError.error}`)} />;
    }

    return (
        <div className={`${styles.graphWrapper} ${isFocusView ? styles.focusView : styles.listView}`}>
            {dataLoading || !sensorThresholds || Object.entries(sensorThresholds).length === 0 ? (
                <div className={`${styles.spinnerBlock} ${isFocusView ? styles.focusView : styles.listView}`}>
                    <StandAloneSpinner />
                </div>
            ) : (
                <HighchartsReact highcharts={Highcharts} options={getGraphConfig()} />
            )}
            {!dataLoading && data.length === 0 && (
                <div className={`${styles.noData}  ${isFocusView ? styles.noDataFocus : styles.noDataList}`}>
                    {txt('Space.NoDataForTimeFrame')}
                </div>
            )}
        </div>
    );
};

export default SpaceMainGraph;
