import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../../../actions/DashboardActions';
import CircleButton from '../../../components/buttons/CircleButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import ChangeSelectedSearchHeader from '../../../components/menu/ChangeSelectedSearchHeader';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';
import styles from './SwitchDashboardModal.module.scss';

export type Props = {
    onClose: () => void;
};

export const SwitchDashboardModalComponent = ({ onClose }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { loading: loadingDashboard, error: fetchDashboardError } = useSelector(
        (state: Store) => state.commonRequests[CommonRequestType.SelectDashboard]
    );
    const { dashboardList, dashboardProps } = useSelector((state: Store) => state.dashboardData);
    const [searchText, setSearchText] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState<string | undefined>(undefined);

    const clickSelect = (id: string): void => {
        dispatch(selectDashboard(id));
        setSelectedDashboard(id);
    };
    useEffect(() => {
        if (fetchDashboardError) {
            setSelectedDashboard(undefined);
        }
    }, [fetchDashboardError]);

    useEffect(() => {
        if (selectedDashboard && !loadingDashboard) {
            onClose();
        }
    }, [loadingDashboard]);

    const filteredDashboards = dashboardList
        ? dashboardList.filter(group => group.name.toLowerCase().includes(searchText.toLowerCase()))
        : [];

    const isActiveDashboard = (id: string): boolean =>
        selectedDashboard ? selectedDashboard === id : !!dashboardProps && id === dashboardProps.id;
    const sortedList = filteredDashboards.sort((group1, group2) => group1.name.localeCompare(group2.name));

    return (
        <ModalWrapper isOpen onClose={onClose} size="small" className={styles.modal} customHeader={<div />}>
            <div className={styles.stickySection}>
                <ChangeSelectedSearchHeader
                    setSearchText={setSearchText}
                    displaySearch={(dashboardList || []).length > 5}
                    headerText="PublicDashboard.SwapDashboard"
                    headerTextWithIcon
                />
                <div className={styles.contentBody}>
                    <div className={styles.list}>
                        {sortedList.map(group => (
                            <div className={styles.listRow} key={`organization-row-${group.id}`}>
                                <div className={styles.listRowText}>
                                    <div className={styles.textOverflow}>{group.name}</div>
                                </div>
                                <CircleButton
                                    onClick={(): void => clickSelect(group.id)}
                                    testAttr="swap-organization"
                                    iconName={isActiveDashboard(group.id) ? 'check' : 'arrow_forward'}
                                    color={isActiveDashboard(group.id) ? 'confirmed' : 'secondary'}
                                    loading={loadingDashboard && isActiveDashboard(group.id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <PrimaryButton
                        color="secondary"
                        title="Cancel"
                        loading={false}
                        disabled={false}
                        onClick={onClose}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default SwitchDashboardModalComponent;
